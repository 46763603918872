<template>
  <div class="lc_info">
    <div class="head">L/C</div>
    <div class="body">
      <div class="inner">
        <div class="left">
          <div class="user">
            <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">{{item.updateUserName}}</div>
              <div class="from_name">{{item.updateUserName}}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="info">
          <div class="action">
            <div>L/C No {{item.documentaryCreditNumber}}</div>
            <div>{{item.lcType}}</div>
          </div>
        </div>
        <div v-if="isFromPic" class="delete" @click="removeLc">
          <img class="close_times" src="@/assets/images/icons/times_gray600.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TwIpDnLinking',
  components: {},
  props: {
    lcLatest: {
      type: Array,
      default: () => []
    },
    isFromPic: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {};
  },
  computed: {
    item() {
      const lcLatest = _.cloneDeep(this.lcLatest);
      if (lcLatest.length === 0) return {};
      // lcTypeを先頭のオブジェクトにマージして返却
      return {
        ...lcLatest[0],
        lcType: _.uniq(_.map(lcLatest, 'lcType')).sort().reverse().join(' '),
      }
    },
  },
  created() {},
  methods: {
    removeLc() {
      this.$store
      .dispatch('SHOW_CONFIRM', 'Are you sure you want to unlink the pool data?')
      .then(() => {
        this.$emit('remove-lc', this.lcLatest);
      })
      .catch(() => {});
    },
  }
};
</script>

<style lang="scss" scoped>
.lc_info {
  display: flex;
  // align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: $color_gray_800;
  .head {
    display: flex;
    align-items: center;
    background: $color_gray_300;
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    width: 131px;
  }
  .body {
    background: #FFFFFF;
    border: 1px solid $color_gray_300;
    border-radius: 0px 6px 6px 0px;
    width: 100%;
  }
  .inner {
    display: flex;
    width: 100%;
    padding: 20px;
    height: auto;
  }
  .user {
    display: flex;
    align-items: center;
    .from_name {
      width: 120px;
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .left {
    margin-right: 32px;
    display: flex;
    align-items: center;
  }
  .action {
    &.linking {
      color: $color_dark_blue;
      cursor: pointer;
    }
  }
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 16px 0 auto;
    width: 40px;
    &:hover {
      opacity: .5;
    }
  }
  .permit_download {
    margin: 0 16px 0 auto;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
    figure > img {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
